import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import getAppliedDate from './getAppliedDate';

// TODO: Das hier darf niemals den default nehmen !!!!!
const formatPersonData = (data, {
    kidsTerminStart = new Date('2024-07-27'),
    teensTerminStart = new Date('2024-07-19'),
    solaEndBewerbungTermin,
    kidsPrice = 130,
    teensPrice = 145,
} = {}) => {
    if (!data) {
        return [];
    }

    const newData = cloneDeep(data);

    return newData.map(person => {
        const keysToDelete = [];
        const keysToFormatAsInt = [];
        const keysToFormatAsArray = [];
        const keysToFormatAsBoolean = [];
        forOwn(person, (value, key) => {
            if (value === null || value === undefined || value === "") {
                keysToDelete.push(key);
            }

            if (typeof value === 'string' && /^-?[0-9]+$/.test(value)) {
                if (!(value.length > 1 &&  value[0] === '0')) {
                    keysToFormatAsInt.push(key);
                }
            }

            // FIXME ACHTUNG: Die Texte mit ] werden auch gemachted
            if (typeof value === 'string' && /\[*\]/.test(value) && value.startsWith('[') && value.endsWith(']')) {
                keysToFormatAsArray.push(key);
            }

            if (value === 'true' || value === 'false') {
                keysToFormatAsBoolean.push(key);
            }
        });

        keysToDelete.forEach(key => delete person[key]);
        keysToFormatAsInt.forEach(key => person[key] = parseInt(person[key]));
        keysToFormatAsArray.forEach(key => person[key] = JSON.parse(person[key]));
        keysToFormatAsBoolean.forEach(key => person[key] = JSON.parse(person[key]));

        if (person.birthdate) {
            const usedTeiliDate = get(person, 'solaTeilnahme', '').includes('Kids') ? kidsTerminStart : teensTerminStart;
            const usedMitarbeiterDate = get(person, 'solaMitarbeit', []).includes('Kids') ? kidsTerminStart : teensTerminStart;
            const usedSolaDate = new Date(get(person, 'solaTeilnahme', '').length ? usedTeiliDate : usedMitarbeiterDate);

            const birthDate = new Date(person.birthdate);
            const diffYear = usedSolaDate.getFullYear() - birthDate.getFullYear();

            if (
                (birthDate.getMonth() > usedSolaDate.getMonth()) ||
                (birthDate.getMonth() === usedSolaDate.getMonth() && birthDate.getDate() > usedSolaDate.getDate())
            ) {
                person.solaAge = diffYear - 1;
            } else {
                person.solaAge = diffYear;
            }
        }

        const bewerbungEndDateNumbers = solaEndBewerbungTermin?.split('.') || [];
        const bewerbungEndDate = new Date(bewerbungEndDateNumbers[2], parseInt(bewerbungEndDateNumbers[1]) - 1, parseInt(bewerbungEndDateNumbers[0]) + 1);
        const hasAppliedAfter = bewerbungEndDate < new Date(getAppliedDate(person));

        person.paid = person.paid || 0;
        person.hasToPay = person.hasToPay || (
            parseInt(person['solaTeilnahme'] === 'Kids' ? kidsPrice : teensPrice) 
            + (hasAppliedAfter ? 15 : 0)
            - (person.geschwisterRabatt3 ? 50 : person.geschwisterRabatt2 ? 30 : 0)
        );
        person.paySum = person.paid - person.hasToPay;

        return person;
    });
};

export default formatPersonData;